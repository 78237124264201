
@media (max-width: 992px) {
  footer {
    padding-top: 0px!important;
    margin-top: 0px!important;
  }
  .contact-form {
    margin-bottom: 45px;
  }
  footer .right-content {
    text-align: center;
  }
  footer .footer-content {
    padding: 60px 0px!important;
  }
}

footer {
  margin-top: 00px;
  padding-top: 70px;
  background: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
}

footer .footer-content {
  padding: 50px 0px;
}

.contact-form {
  position: relative;
  z-index: 10;
  padding: 25px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  
}

footer .right-content {
  align-self: center;
}

.contact-form input,
.contact-form textarea {
  color: #777;
  font-size: 13px;
  border: 1px solid #eee;
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form textarea {
  height: 50px;
  resize: none;
  padding: 20px;
}

.contact-form {
  text-align: center;
}

.contact-form ::-webkit-input-placeholder { /* Edge */
  color: #7a7a7a;
}

.contact-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #7a7a7a;
}

.contact-form ::placeholder {
  color: #7a7a7a;
}

.footer-content .right-content {
  color: #000;
}

.footer-content .right-content h2 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 42px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer-content .right-content h2 em {
  font-style: normal;
  color: #FC3;
}

.footer-content .right-content p {
  color: #FFF;
}

.footer-content .right-content a {
  color: #FFF;
}

.footer-content .right-content a:hover {
  color: #FC3;
}

footer .social {
  overflow: hidden;
  margin-top: 20px;
}

footer .social li:first-child {
  margin-left: 0px;
}

footer .social li {
  margin: 0px 7.5px;
  display: inline-block;
}

footer .social li a {
  color: #000;
  text-align: center;
  background-color: transparent;
  border: 1px solid #000;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .social li a:hover {
  background-color: #fba70b;
  border-color: #fba70b;
  color: #fff;
}

footer .copyright {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.88px;
  text-transform: capitalize;
}

footer .sub-footer {
  padding: 20px 0px;
  border-top: 1px solid rgba(250,250,250,0.3);
  text-align: center;
}

footer .sub-footer a {
	color: #FFF;
}

footer .sub-footer a:hover {
	color: #FC3;
}

footer .sub-footer p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

@media (max-width: 991px) {
  footer .copyright {
    text-align: center;
  }
  footer .social {
    text-align: center;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/
#preloader {
  overflow: hidden;
  background-image: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

#preloader .jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}