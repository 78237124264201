#faq{
    position: relative;
}

.faq-grid{
    position: absolute;
    top: 0;
    right: 15%;

}

/*******************************
* ACCORDION WITH TOGGLE ICONS
* Does not work properly if "in" is added after "collapse".
*******************************/
	.panel-group .panel {
		border-radius: 0;
		box-shadow: none;
		border-color: #EEEEEE;
	}

	.panel-default > .panel-heading {
		padding: 0;
		border-radius: 0;
		color: #212121;
		background-color: #FAFAFA;
		border-color: #EEEEEE;
	}

	.panel-title {
		font-size: 14px;
	}

	.panel-title > a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}

	.more-less {
		float: right;
		color: #212121;
	}

	.panel-default > .panel-heading + .panel-collapse > .panel-body {
		border-top-color: #EEEEEE;
	}
	.panel-body{
		background: #fff;
		padding: 30px 10px;
	}

/* ----- v CAN BE DELETED v ----- */


.demo {
	padding-top: 30px;
	text-align: left !important;
position: relative;
}

.demo-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #212121;
	text-align: center;
}

.demo-footer > a {
	text-decoration: none;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}

.faq-icon{
	position: absoulte;
	float: right;
	top: 0;
}