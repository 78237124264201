.header-area{
    position: relative !important;
}
.started-BTN{
    background: #63a228;
    border: 1px solid #63a228;
    border: none;
    padding: 12px 30px;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
}
.started-BTN:hover{
    background: #dc3545;
    transition: 0.3s;
    -webkit-box-shadow: 0 8px 6px -6px #dc3545;
    -moz-box-shadow: 0 8px 6px -6px #dc3545;
    box-shadow: 0 8px 16px -6px #dc3545;
}

.tnc-header-bg{
    background: url('../../assets/img/contact.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color;
    background-color: hsla(0, 0%, 0%, 0.6);
    padding: 100px 0;
}
.tnc-header-bg h1{
    font-size: 2.6rem;
    letter-spacing: 3px;
}
.tnc-body{
    background-color: #f5f5f5;
    padding: 5rem 0 7rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.tnc-text{
    max-width: 900px;
    width: 100%;
    padding: 20px 30px;
    border-radius: 4px;
    line-height: 30px;
    font-weight: 400;
}
.tnc-text p{
    font-size: 16px;
    line-height: 1.8;
}
.tnc-text ol,
.tnc-text ul{
    padding-left: 0.5rem;
    margin: 1.8rem 0;
}
.tnc-text li{
    font-size: 1.8rem !important;
    line-height: 30px;
    font-weight: 700;
    padding-left: 0.5rem;
    color: #63a228 ;
}
.gst-Container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.gst{
    border-radius: 16px;
    margin-top: -75px;
    background-color: #fff;
    box-shadow: 1px 0 20px rgb(0 0 0 / 7%);
    padding: 26px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: calc(100% - 16%); */
}
.gst p{
    font-size: 18px;
    line-height: 30px;
}
.btn-Container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tnc-footer{
    margin-top: 150px;
}
.tnc-footer p{
    color: rgba(255, 255, 255, 0.719);
    font-size: 1.1rem;
    font-weight: 600;
}
.tnc-footer a{
    color: #63a228 !important;
}
@media(max-width:600px){
    .tnc-header-bg{
        padding: 60px 0;
    }
    .tnc-header-bg h1{
        font-size: 22px;
        display: block;
        width: 100%;
    }
    .tnc-header-bg a{
        font-size: 14px;
    }
    .tnc-text{
        padding: 1.5rem 1rem;
        color: #333;
    }
    .tnc-text ol,
    .tnc-text ul{
        padding-left: 0.35rem;
        margin: 1.25rem 0;
        text-align: center;
    }
    .tnc-text li{
        font-size: 1.6rem !important;
        line-height: 30px;
        font-weight: 700;
        padding-left: 0.5rem;
        color: #63a228 ;
    }

}
@media(max-width:990px){
    .gst-Container{
        max-width: unset;
    }
    .gst{
        flex-direction: column;
        width: 100%;
    }
    .gst div{
        width: 100%;
    }
    .btn-Container{
        justify-content: flex-start;
    }
}