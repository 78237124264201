body {
  font-size: 100%;

  margin: 0;
  padding: 0;
}
.container .main_header {
  padding-top: 10%;
}
.container .main_header__title {
  position: relative;
  height: 50px;
  vertical-align: baseline;
  color: #369593;
  text-transform: uppercase;
  font-size: 1.5em;
  margin-left: 1%;
}
.container .container__title {
  position: relative;
  height: 30px;
  vertical-align: baseline;
  font-size: 0.9em;
  font-weight: 400;
  color: #f6f6f6;
  text-transform: uppercase;
  border-bottom: 1px solid #f9f9f9;
}
.container .box:hover, .container .add_box:hover {
  box-shadow: 15px 15px 13px rgba(85, 85, 85, 0.3);
  transition: 0.4s;
  transform: translate(-1%, -1%);
}
.container .box, .container .add_box {
  position: relative;
  height: 200px;
  box-shadow: 1px 1px 5px rgba(85, 85, 85, 0.5);
  border-radius: 4px;
  margin-bottom: 7%;
  transition: 1s;
}
.container .box a:hover .box__title, .container .add_box a:hover .box__title {
  height: 35%;
  transition: 1s;
}
.container .box a:hover .box__title p, .container .add_box a:hover .box__title p {
  opacity: 1;
}
.container .box img, .container .add_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.container .box .box__title, .container .add_box .box__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #f6f6f6;
  border-radius: 0 0 8px 8px;
  vertical-align: middle;
  transition: 0.5s;
  transition-timing-function: ease-out;
}
.container .box .box__title p, .container .add_box .box__title p {
  opacity: 0;
  text-align: center;
  font-size: 0.8em;
  margin: 0;
  transition-delay: 0.2s;
  transition: 0.2s;
  transition-timing-function: ease-out;
}
.container .box .box__title h6, .container .add_box .box__title h6 {
  text-align: center;
  text-transform: uppercase;
  margin: 7% 0 0 0;
  font-size: 0.9em;
}
.container .add_box {
  background-color: #f9f9f9;
}
.container .add_box a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.container .add_box svg {
  display: block;
  margin: auto;
  margin-top: 23.5%;
  transition: 0.3s;
  transition-timing-function: ease-out;
}
.container .add_box .add_box__title {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #bababa;
  top: 60%;
}
.container .add_box .add_box__title h6 {
  text-transform: uppercase;
}
.container .add_box a:hover svg {
  margin-top: 21.5%;
}
