#about{
    position: relative;
}

.about-grid{
    position: absolute;
    bottom: 0;
    left: 10%;
}

.about-quality{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 20px;
}
.quality-1{
    padding-bottom:20px;
}