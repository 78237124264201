@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;
400;
500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: #888;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #3A7F12;
  color: #fff;
}

::-moz-selection {
  background: #3A7F12;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

a.main-button-slider {
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #8EC63E;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}

a.main-button-slider:hover {
  background-color: #3A7F12;
}

a.main-button {
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #63a228;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}

a.main-button:hover {
  background-color: #3A7F12;
}

button.main-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: #63a228;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}

button.main-button:hover {
  background-color: #3A7F12;
}


.center-heading {
  margin-bottom: 70px;
}

.center-heading h2 {
  margin-top: 0px;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #1e1e1e;
  margin-bottom: 25px;
}

h6 em,
.center-heading h2 em {
  font-style: normal;
  color: #63a228;
}

.center-heading p {
  font-size: 15px;
  color: #888;
  text-align: center;
}


#about{
    position: relative;
}

.about-grid{
    position: absolute;
    bottom: 0;
    left: 10%;
}

.about-quality{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 20px;
}
.quality-1{
    padding-bottom:20px;
}
#faq{
    position: relative;
}

.faq-grid{
    position: absolute;
    top: 0;
    right: 15%;

}

/*******************************
* ACCORDION WITH TOGGLE ICONS
* Does not work properly if "in" is added after "collapse".
*******************************/
	.panel-group .panel {
		border-radius: 0;
		box-shadow: none;
		border-color: #EEEEEE;
	}

	.panel-default > .panel-heading {
		padding: 0;
		border-radius: 0;
		color: #212121;
		background-color: #FAFAFA;
		border-color: #EEEEEE;
	}

	.panel-title {
		font-size: 14px;
	}

	.panel-title > a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}

	.more-less {
		float: right;
		color: #212121;
	}

	.panel-default > .panel-heading + .panel-collapse > .panel-body {
		border-top-color: #EEEEEE;
	}
	.panel-body{
		background: #fff;
		padding: 30px 10px;
	}

/* ----- v CAN BE DELETED v ----- */


.demo {
	padding-top: 30px;
	text-align: left !important;
position: relative;
}

.demo-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #212121;
	text-align: center;
}

.demo-footer > a {
	text-decoration: none;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}

.faq-icon{
	position: absoulte;
	float: right;
	top: 0;
}
.right-image-decor {
  /* background-image: url(../images/right-bg-decor.png); */
  background-repeat: no-repeat;
  background-position: right center;
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 110%;
  margin-top: -200px;
}

#testimonials {
  padding-top: 100px;
  position: relative;
  z-index: 9;
}

#testimonials .item {
  position: relative;
}

#testimonials .item .testimonial-content {
  background: rgb(142, 198, 62);
  background: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  margin-left: 60px;
  padding: 40px 30px;
  text-align: center;
  border-radius: 5px;
}

#testimonials .item .testimonial-content ul li {
  display: inline-block;
  margin: 0px 1px;
}

#testimonials .item .testimonial-content ul li i {
  color: #fff;
  font-size: 13px;
}

#testimonials .item .testimonial-content h4 {
  font-size: 22px;
  margin-top: 20px;
  color: #fff;
}

#testimonials .item .testimonial-content p {
  color: #fff;
  font-style: italic;
  font-weight: 200;
  margin: 20px 0px 15px 0px;
}

#testimonials .item .testimonial-content span {
  color: #fff;
  font-weight: 200;
  font-size: 13px;
}

#testimonials .author img {
  max-width: 120px;
  margin-bottom: -60px;
}

.owl-carousel .owl-nav button.owl-next span {
  z-index: 10;
  top: 45%;
  border-radius: 10px;
  font-size: 46px;
  color: #fff;
  right: -210px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  background: rgb(142, 198, 62);
  background: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  position: absolute;
}

.owl-carousel .owl-nav button.owl-prev span {
  z-index: 9;
  top: 30%;
  border-radius: 10px;
  font-size: 46px;
  color: #fff;
  right: -150px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  background: rgb(142, 198, 62);
  background: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(142, 198, 62) 100%);
  position: absolute;
}

@media screen and (max-width: 1060px) {
  .owl-carousel .owl-nav {
    display: none!important;
  }
}


#about {
  padding: 0px 0px;
  position: relative;
  z-index: 9;
}

@media (max-width: 992px) {
  .features-item {
    margin-bottom: 45px;
  }
}

.features-item {
  border-radius: 5px;
  padding: 60px 30px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  position: relative;
}

.features-item h2 {
  z-index: 9;
  position: absolute;
  top: 15px;
  left: 45px;
  font-size: 100px;
  font-weight: 600;
  color: #fdefe9;
}

.features-item img {
  z-index: 10;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
}

.features-item h4 {
  margin-top: 0px;
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 25px;
}

.features-item .main-button {
  margin-top: 35px;
  display: inline-block;
}


/*
---------------------------------------------
promotion
---------------------------------------------
*/

.left-image-decor {
 background-image: url(../../static/media/bg3.19527aa5.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 200%;
  margin-top: 00px;
}

#promotion {
  padding: 100px 0px;
}

#promotion .left-image {
  align-self: center;
}

#promotion .right-text ul {
  padding: 0;
  margin: 0;
}

#promotion .right-text ul li {
  display: inline-block;
  margin-bottom: 60px;
}

#promotion .right-text ul li:last-child {
  margin-bottom: 0px;
}

#promotion .right-text img {
  display: inline-block;
  float: left;
  margin-right: 30px;
}

#promotion .right-text h4 {
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 15px;
  margin-top: 3px;
}


 span, b {
	 font-size: 1.2rem;
}
 span {
	 color: #444;
	 margin-bottom: 0.5rem;
}

.staggered-grid a {
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
	 width: 100%;
	 height: 100%;
	 color: black;
	 text-decoration: none;
	 opacity: 0;
	 border-radius: 2rem;
	 background-color: rgba(255, 255, 255, 0.7);
	 transition: opacity 200ms ease-out;
}
 .staggered-grid {
	 display: grid;
	 width: 30rem;
	 min-height: 100vh;
	 padding: 2rem 0;
	 grid-template-columns: repeat(2, 1fr);
	 grid-template-rows: repeat(4, 9rem);
	 grid-gap: 1.5rem;
	 -webkit-animation: fade-and-slide-in 2000ms 1 forwards cubic-bezier(0.17, 0.91, 0.73, 1.03);
	         animation: fade-and-slide-in 2000ms 1 forwards cubic-bezier(0.17, 0.91, 0.73, 1.03);
}
 @-webkit-keyframes fade-and-slide-in {
	 0% {
		 -webkit-transform: translateY(-100%);
		         transform: translateY(-100%);
		 opacity: 0;
	}
	 100% {
		 -webkit-transform: translateY(0);
		         transform: translateY(0);
		 opacity: 1;
	}
}
 @keyframes fade-and-slide-in {
	 0% {
		 -webkit-transform: translateY(-100%);
		         transform: translateY(-100%);
		 opacity: 0;
	}
	 100% {
		 -webkit-transform: translateY(0);
		         transform: translateY(0);
		 opacity: 1;
	}
}
 .picture-container {
	 background-position: center center;
	 background-size: auto 100%;
	 background-repeat: no-repeat;
	 border-radius: 2rem;
	 box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .picture-container:hover a {
	 opacity: 1;
}
 div.picture-container:nth-child(1) {
	 background-image: url(https://source.unsplash.com/nvzvOPQW0gc/350);
	 grid-column-start: 1;
	 grid-row-start: 1;
	 grid-row-end: 3;
}
 div.picture-container:nth-child(2) {
	 background-image: url(https://source.unsplash.com/8ioenvmof-I/350);
	 grid-column-start: 2;
	 grid-row-start: 2;
	 grid-row-end: 4;
   z-index:2;

}
 div.picture-container:nth-child(3) {
	 background-image: url(https://source.unsplash.com/kXQ3J7_2fpc/350);
	 grid-column-start: 1;
	 grid-row-start: 3;
	 grid-row-end: 5;
}
#promotion{
  position: relative;
}
 
.topgrid{
  position: absolute;
  top: 0%;
  left: 20%;
  z-index:1;
}
.bottomgrid{
  position: absolute;
  top: 20%;
  left: 20%;
  z-index:1;

}


:root {
  --size-pictureframe: 200px;
}
.circles-gallery {
  position: relative;
  height: 200px;
  height: var(--size-pictureframe);
  margin-left: calc(200px);
  margin-left: calc(var(--size-pictureframe) );
  padding: 0 50px;
}
.circles-gallery > * {
  position: relative;
  display: inline-block;
  margin-left: calc(200px * -0.1);
  margin-left: calc(var(--size-pictureframe) * -0.1);
  margin-bottom: 30px;
  height: 100%;
  border: solid .5rem white;
  aspect-ratio: 1;
  border-radius: 50%;
  z-index: 10;
}
.circles-gallery > h6{
  margin-left: calc(200px * -0.01);
  margin-left: calc(var(--size-pictureframe) * -0.01);
  border: none;

}
.circles-gallery > *:hover,
.circles-gallery > *:focus-within {
  z-index: +20;
  opacity: 1;
}

/* demo purpose only - may be removed */
#work{
  height: calc(200px + 5vh);
  height: calc(var(--size-pictureframe) + 5vh);
  margin: 0 auto;
  padding: 2.5vh;
  position: relative;
  min-height: 110vh;

}
/* -------------------- */
@media only screen and (max-width: 600px) {
#work{
  min-height: 320vh;
}
.circles-gallery > h6{
  margin-left: calc(200px * -0.5);
  margin-left: calc(var(--size-pictureframe) * -0.5);

}.circles-gallery > * {
 margin-bottom: 30px;
 margin-left: calc(200px * -0.9);
 margin-left: calc(var(--size-pictureframe) * -0.9);

}
}

.brute-css{
  display: none;
}

@media(max-width:990px){
  .circles-gallery{
    display: none;
  }
  .brute-css{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .brute-css img{
    border-radius: 50%;
    height: 275px;
    width:  275px;
    object-fit: cover;
  }
  .brute-css p{
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1.5rem 0;
  }
  #work,
  #brute{
    height: auto !important;
  }
}

.slick-slide {
    margin: 0px 20px;
}

.slick-slide img {
    width: 100%;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
:root {
	 --font-size: 0.8em;
	 --bg1: #f5f8f7;
	 --blue: #3498db;
	 --green: #2ecc71;
	 --purple: #9b59b6;
	 --gold: #f1c40f;
	 --red: #e74c3c;
	 --orange: #e67e22;
	 --shadow1: 0 2px 4px #000 26, 0 3px 6px #000 1f;
	 --shadow2: 0 2px 6px #000 44, 0 4px 7px #000 22;
}
#badges{
    background-color: #fff;
	margin-bottom:50px;
}

 ul.infoGraphic {
	 font-size: 0.8em;
	 font-size: var(--font-size);
	 display: flex;
	 flex-wrap: wrap;
	 justify-content: center;
}
 ul.infoGraphic li {
	 position: relative;
	 width: 100%;
	 max-width: 25em;
	 background: #fff;
	 border-radius: 0.5em;
	 padding: 0.5em;
	 z-index: 1;
	 transition: all 0.2s;
	 cursor: pointer;
}
 ul.infoGraphic li .numberWrap {
	 position: absolute;
}
 ul.infoGraphic li .number {
	 font-family: "maven pro", sans-serif;
	 font-size: 13em;
	 font-weight: 900;
	 width: 0.6em;
	 text-align: center;
}
 ul.infoGraphic li .number.fontColor1 {
	 color: #3498db;
	 color: var(--blue);
}
 ul.infoGraphic li .number.fontColor2 {
	 color: #2ecc71;
	 color: var(--green);
}
 ul.infoGraphic li .number.fontColor3 {
	 color: #9b59b6;
	 color: var(--purple);
}
 ul.infoGraphic li .number.fontColor4 {
	 color: #f1c40f;
	 color: var(--gold);
}
 ul.infoGraphic li .number.fontColor5 {
	 color: #e74c3c;
	 color: var(--red);
}
 ul.infoGraphic li .number.fontColor6 {
	 color: #e67e22;
	 color: var(--orange);
}
 ul.infoGraphic li .coverWrap {
	 -webkit-transform: rotate(130deg);
	         transform: rotate(130deg);
	 position: absolute;
	 width: 18em;
	 height: 15em;
	 left: -3em;
	 top: -1em;
}
 ul.infoGraphic li .coverWrap .numberCover {
	 position: absolute;
	 background: #f5f8f7;
	 width: 18em;
	 height: 6em;
     margin-left: 10px;
	 border-radius: 50% 50% 0 0;
	 border-bottom: 3px solid #f5f8f7;
	 transition: all 0.4s;
}
 ul.infoGraphic li .coverWrap .numberCover::before {
	 position: absolute;
	 content: "";
	 bottom: 5px;
	 left: 4em;
	 right: 4em;
	 top: 5em;
	 box-shadow:0 2px 6px #000 44, 0 4px 7px #000 22;
	 border-radius: 100px / 10px;
	 z-index: -1;
}
 ul.infoGraphic li .coverWrap .numberCover::after {
	 position: absolute;
	 bottom: 0;
	 content: "";
	 left: -10%;
	 width: 120%;
	 height: 150%;
	 background: radial-gradient(at bottom, #486685 33, transparent, transparent);
	 z-index: 1;
}
 ul.infoGraphic li .content {
	 margin: 8em 3em 1em 7em;
	 position: relative;
}
 ul.infoGraphic li .content h2 {
	 font-size: 1.7em;
	 font-weight: 500;
	 text-align: center;
	 margin-bottom: 10px;
	 text-transform: uppercase;
}
 ul.infoGraphic li .content p {
	 line-height: 1.5em;
}
 ul.infoGraphic li:hover .coverWrap .numberCover {
	 border-radius: 100%;
}
 .icon {
	 position: absolute;
	 font-size: 2rem;
	 text-align: center;
	 top: -1.3em;
	 left: 50%;
	 -webkit-transform: translatex(-50%);
	         transform: translatex(-50%);
}
 .icon:before {
	 color: #666;
	 font-family: FontAwesome;
	 font-style: normal;
	 font-weight: normal;
	 text-decoration: inherit;
}
 .iconCodepen:before {
	 content: "\f1cb";
}
 .iconSocial:before {
	 content: "\f08a";
}
 .iconAirplane:before {
	 content: "\f1d9";
}
 .iconMap:before {
	 content: "\f278";
}
 .iconBulb:before {
	 content: "\f0eb";
}
 .iconPeace:before {
	 content: "\f25b";
}



/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background-color: #fff;
  height: 100px!important;
  position: fixed!important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}


.background-header .logo,
.background-header .main-nav .nav li a {
  color: #1e1e1e!important;
}

.background-header .main-nav .nav li:hover a {
  color: #63a228!important;
}

.background-header .nav li a.active {
  color: #63a228!important;
}

.header-area {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 80px;
  color: #191a20;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  transition: all 0.3s ease 0s;
}


.header-area .main-nav .nav {
  float: left;
  margin-top: 24px;
  margin-left: 15%;
  margin-right: 0px;
  background-color: #fff;
  padding: 0px 20px;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #7a7a7a;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a {
  color: #63a228;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 35px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #7a7a7a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  opacity: 0;
  -webkit-transform: translateY(-2em);
          transform: translateY(-2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  display: block;
  background: #fff;
  color: #7a7a7a!important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  border-bottom: 1px solid #f5f5f5;
}

.header-area .main-nav .nav li.submenu ul li a:before {
  content: '';
  position: absolute;
  width: 0px;
  height: 40px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  transition: all 0.3s ease 0s;
  background: #63a228;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #63a228!important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 85%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a.active {
  color: #63a228;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .logo {
    text-align: center;
    float: none!important;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    margin-top: 0px !important;
    display: none;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #63a228!important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 992px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}


/* 
---------------------------------------------
welcome
--------------------------------------------- 
*/
.welcome-area {
  width: 100%;
  height: 110vh;
  min-height: 793px;
  background-image: url(../../static/media/bg10.83d40455.png);
  background-repeat: no-repeat;
  background-position: right top;
}

.welcome-area .header-text .left-text {
  position: relative;
  align-self: center;
  margin-top: 35%;
  -webkit-transform: perspective(1px) translateY(-30%) !important;
          transform: perspective(1px) translateY(-30%) !important;
}

.welcome-area .header-text h1 {
  font-weight: 900;
  font-size: 52px;
  line-height: 80px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #191a20;
}

.welcome-area .header-text h1 em {
  font-style: normal;
  color: #63a228;
}

.welcome-area .header-text p {
  font-weight: 300;
  font-size: 17px;
  color: #7a7a7a;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 1420px) {
  .welcome-area .header-text {
    width: 70%;
    margin-left: 5%;
  }
}

@media (max-width: 1320px) {
  .welcome-area .header-text .left-text {
    margin-top: 50%;
  }
  .welcome-area .header-text {
    width: 50%;
    margin-left: 5%;
  }
}

@media (max-width: 1140px) {
  .welcome-area {
    height: auto;
  }
  .welcome-area .header-text {       
    width: 92%;
    padding-top: 793px;
  }
  .welcome-area .header-text .left-text {
    margin-top: 5%;
    -webkit-transform: none !important;
            transform: none !important;
  }
}
@media (max-width: 810px) {
  .welcome-area {
    background-size: contain;
  }

  .welcome-area .header-text {
    padding-top: 100%;
    margin-left: 0;
  }
    
  .container-fluid .col-md-12 {
    margin-right: 0px;
    padding-right: 0px;
  }
}

.button {
  position: relative;
  padding: 1.6em 2.8em;
  text-decoration: none;
}
.button__canvas {
  --offset: 32px;
  position: absolute;
  top: calc(var(--offset) * -1);
  left: calc(var(--offset) * -1);
  width: calc(100% + var(--offset) * 2);
  height: calc(100% + var(--offset) * 2);
  transition: opacity 2s ease;
}
.button__text {
  position: relative;
  color: white;
  font-weight: bold;
  letter-spacing: 0.02em;
  pointer-events: none;
}
.button:hover .button__canvas {
  opacity: 0.85;
  transition: opacity 0.2s ease;
}
.button:active .button__canvas {
  opacity: 1;
  transition: none;
}
#bruteNav{
  display: none;
}
@media(max-width:990px){
  #bruteNav{
    position: absolute;
    display: block;
    background-color: #f5f5f5;
    top: 92.8px;
    left: 0;
    right: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 1rem ;
  }
  #bruteNav ul{
    width: 100%;
    padding-left: 0;
  }
  #bruteNav li{
    font-size: 1.25rem;
    margin: 0.65rem 0;
    padding: 0.5rem 0;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  footer {
    padding-top: 0px!important;
    margin-top: 0px!important;
  }
  .contact-form {
    margin-bottom: 45px;
  }
  footer .right-content {
    text-align: center;
  }
  footer .footer-content {
    padding: 60px 0px!important;
  }
}

footer {
  margin-top: 00px;
  padding-top: 70px;
  background: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
}

footer .footer-content {
  padding: 50px 0px;
}

.contact-form {
  position: relative;
  z-index: 10;
  padding: 25px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  
}

footer .right-content {
  align-self: center;
}

.contact-form input,
.contact-form textarea {
  color: #777;
  font-size: 13px;
  border: 1px solid #eee;
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form textarea {
  height: 50px;
  resize: none;
  padding: 20px;
}

.contact-form {
  text-align: center;
}

.contact-form ::-webkit-input-placeholder { /* Edge */
  color: #7a7a7a;
}

.contact-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #7a7a7a;
}

.contact-form ::placeholder {
  color: #7a7a7a;
}

.footer-content .right-content {
  color: #000;
}

.footer-content .right-content h2 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 42px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer-content .right-content h2 em {
  font-style: normal;
  color: #FC3;
}

.footer-content .right-content p {
  color: #FFF;
}

.footer-content .right-content a {
  color: #FFF;
}

.footer-content .right-content a:hover {
  color: #FC3;
}

footer .social {
  overflow: hidden;
  margin-top: 20px;
}

footer .social li:first-child {
  margin-left: 0px;
}

footer .social li {
  margin: 0px 7.5px;
  display: inline-block;
}

footer .social li a {
  color: #000;
  text-align: center;
  background-color: transparent;
  border: 1px solid #000;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  transition: all 0.3s ease 0s;
}

footer .social li a:hover {
  background-color: #fba70b;
  border-color: #fba70b;
  color: #fff;
}

footer .copyright {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.88px;
  text-transform: capitalize;
}

footer .sub-footer {
  padding: 20px 0px;
  border-top: 1px solid rgba(250,250,250,0.3);
  text-align: center;
}

footer .sub-footer a {
	color: #FFF;
}

footer .sub-footer a:hover {
	color: #FC3;
}

footer .sub-footer p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

@media (max-width: 991px) {
  footer .copyright {
    text-align: center;
  }
  footer .social {
    text-align: center;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/
#preloader {
  overflow: hidden;
  background-image: linear-gradient(145deg, rgb(142, 198, 62) 0%, rgb(58,127,18) 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

#preloader .jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#toggle{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin: 0 200px;
    text-align: center;
}

.hemlo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin: 30px;
}

.services-right-text,
.services-left-art{
text-align: right;
}

.hemlo ul li{
    line-height: 50px;
    cursor: pointer;
  position: relative;
  background: white;
  margin-bottom: 2em;
  padding: 1em;
  font-weight: bold;
  font-size: 1em;
  border: none;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07); 
}
@media only screen and (max-width: 600px) {
.services-left-art{
    display: none;
}
.hemlo ul li{
    line-height: 20px;
    width: 100%;
}#toggle{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 0 0px;
    text-align: center;
}

.hemlo{
    display: block;
    grid-gap: 10px;
    margin: 30px;
}
  }
.header-area{
    position: relative !important;
}
.contact-us-header-bg{
    background: url(../../static/media/contact.3e71ebac.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 100px 0;
}
.contact-us-header-bg h1{
    font-size: 45px;
    letter-spacing: 2px;
}
.contact-body{
    display: flex;
    justify-content: space-between;
    padding-bottom: 6.5rem;
}
.contact-body p{
    line-height: 30px;
    font-size: 17px;
    font-weight: normal;
}
.contact-BTN{
    background: #63a228;
    border: 1px solid #63a228;
    border: none;
    padding: 12px 30px;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
}
.contact-BTN:hover{
    background: #dc3545;
    transition: 0.3s;
    box-shadow: 0 8px 16px -6px #dc3545;
}

.contact-body .left,
.contact-body .right{
    padding: 30px;
    border-radius: 12px;
    margin-top: -3rem;
}

/* Left Left Left Left Left Left Left Left Left Left Left Left Left Left Left Left  */
.contact-body .left{
    background: #fff;
    width: 33.333333%;
}
.contact-body .head{
    color: #242424;
    font-size: 22px;
}
.contact-body .left li{
    display: flex;
    margin-bottom: 23px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 23px;
}
.contact-body .left li:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
.contact-body .left li img{
    height: 40px;
    width: 40px;
}
.contact-body .left li h3{
    font-size: 16px;
}
.contact-body .left li p span{
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid chartreuse;
}

/* RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT */

.contact-body .right{
    background: #fff;
    height: -webkit-min-content;
    height: min-content;
    width: 66.66666666%;
}
.contact-body .right .dropus img{
    height: 40px;
    width: 40px;
}
.contact-body .right .message-form p{
    color: #63a228;
    font-size: 17px;
    margin-bottom: 0.35rem;
}
.contact-body .right .message-form input{
    width: 100%;
    background: #f0f0f0;
    padding: 12px 8px;
    border: none;
    border-radius: 4px;
}
.contact-body .right .message-form textarea{
    background: #f0f0f0;
    padding: 12px 8px;
    border: none;
    border-radius: 4px;
    min-height: 150px;
}
.contact-body .right .message-form input:focus,
.contact-body .right .message-form textarea{
    outline: none;
}

.input-box{
    display: flex;
}
.input-item{
    width: 50%;
}

.gst-Container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.gst{
    border-radius: 16px;
    margin-top: -75px;
    background-color: #fff;
    box-shadow: 1px 0 20px rgb(0 0 0 / 7%);
    padding: 26px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: calc(100% - 16%); */
}
.gst p{
    font-size: 18px;
    line-height: 30px;
}
.contact-footer{
    margin-top: 150px;
}
.contact-footer p{
    color: rgba(255, 255, 255, 0.719);
    font-size: 1.1rem;
    font-weight: 600;
}
.contact-footer a{
    color: #63a228 !important;
}
.getstContainer{
    padding: 0 28px;
}

/* Media Queries */

@media(max-width:1199px){
    .contact-body p{
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
@media(max-width:990px){
    .contact-body{
        flex-direction: column;
    }    
    .contact-body .left,
    .contact-body .right{
        padding: 30px;
        border-radius: 12px;
        width: 100%;
        margin: 1rem 0 !important;
    }   
    .contact-body p{
        font-size: 16px !important;
        line-height: 30px !important;
    }
    .gst-Container{
        max-width: unset !important;
    }
    .gst{
        flex-direction: column;
        width: 100%;
    }
    .gst div{
        width: 100%;
    }
    .btn-Container{
        justify-content: flex-start;
    }
}
@media(max-width:600px){
    .contact-us-header-bg{
        padding: 60px 0;
    }
    .contact-us-header-bg h1{
        font-size: 22px;
        display: block;
        width: 100%;
    }
    .contact-us-header-bg a{
        font-size: 14px;
    }
    .sm-no-pad{
        padding: 0 !important;
    }
    .getstContainer{
        margin-top: -2rem;
    }
    .gst{
        padding: 26px 20px;
    }
    .sm-bp{
        padding-bottom: 80px;
    }
    .contact-body .right{
        padding: 1.5rem 12px;
    }        
    .input-box{
        flex-direction: column;
    }
    .input-item{
        width: 100%;
    }
    .contact-BTN{
        font-size: 14px;
        padding: 10px 20px;
    }
}

#WPLOGO{
    font-size: 40px;
    color: #25D366;
}
.header-area{
    position: relative !important;
}
.started-BTN{
    background: #63a228;
    border: 1px solid #63a228;
    border: none;
    padding: 12px 30px;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
}
.started-BTN:hover{
    background: #dc3545;
    transition: 0.3s;
    box-shadow: 0 8px 16px -6px #dc3545;
}

.tnc-header-bg{
    background: url(../../static/media/contact.3e71ebac.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color;
    background-color: hsla(0, 0%, 0%, 0.6);
    padding: 100px 0;
}
.tnc-header-bg h1{
    font-size: 2.6rem;
    letter-spacing: 3px;
}
.tnc-body{
    background-color: #f5f5f5;
    padding: 5rem 0 7rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.tnc-text{
    max-width: 900px;
    width: 100%;
    padding: 20px 30px;
    border-radius: 4px;
    line-height: 30px;
    font-weight: 400;
}
.tnc-text p{
    font-size: 16px;
    line-height: 1.8;
}
.tnc-text ol,
.tnc-text ul{
    padding-left: 0.5rem;
    margin: 1.8rem 0;
}
.tnc-text li{
    font-size: 1.8rem !important;
    line-height: 30px;
    font-weight: 700;
    padding-left: 0.5rem;
    color: #63a228 ;
}
.gst-Container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.gst{
    border-radius: 16px;
    margin-top: -75px;
    background-color: #fff;
    box-shadow: 1px 0 20px rgb(0 0 0 / 7%);
    padding: 26px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: calc(100% - 16%); */
}
.gst p{
    font-size: 18px;
    line-height: 30px;
}
.btn-Container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tnc-footer{
    margin-top: 150px;
}
.tnc-footer p{
    color: rgba(255, 255, 255, 0.719);
    font-size: 1.1rem;
    font-weight: 600;
}
.tnc-footer a{
    color: #63a228 !important;
}
@media(max-width:600px){
    .tnc-header-bg{
        padding: 60px 0;
    }
    .tnc-header-bg h1{
        font-size: 22px;
        display: block;
        width: 100%;
    }
    .tnc-header-bg a{
        font-size: 14px;
    }
    .tnc-text{
        padding: 1.5rem 1rem;
        color: #333;
    }
    .tnc-text ol,
    .tnc-text ul{
        padding-left: 0.35rem;
        margin: 1.25rem 0;
        text-align: center;
    }
    .tnc-text li{
        font-size: 1.6rem !important;
        line-height: 30px;
        font-weight: 700;
        padding-left: 0.5rem;
        color: #63a228 ;
    }

}
@media(max-width:990px){
    .gst-Container{
        max-width: unset;
    }
    .gst{
        flex-direction: column;
        width: 100%;
    }
    .gst div{
        width: 100%;
    }
    .btn-Container{
        justify-content: flex-start;
    }
}
body {
  font-size: 100%;

  margin: 0;
  padding: 0;
}
.container .main_header {
  padding-top: 10%;
}
.container .main_header__title {
  position: relative;
  height: 50px;
  vertical-align: baseline;
  color: #369593;
  text-transform: uppercase;
  font-size: 1.5em;
  margin-left: 1%;
}
.container .container__title {
  position: relative;
  height: 30px;
  vertical-align: baseline;
  font-size: 0.9em;
  font-weight: 400;
  color: #f6f6f6;
  text-transform: uppercase;
  border-bottom: 1px solid #f9f9f9;
}
.container .box:hover, .container .add_box:hover {
  box-shadow: 15px 15px 13px rgba(85, 85, 85, 0.3);
  transition: 0.4s;
  -webkit-transform: translate(-1%, -1%);
          transform: translate(-1%, -1%);
}
.container .box, .container .add_box {
  position: relative;
  height: 200px;
  box-shadow: 1px 1px 5px rgba(85, 85, 85, 0.5);
  border-radius: 4px;
  margin-bottom: 7%;
  transition: 1s;
}
.container .box a:hover .box__title, .container .add_box a:hover .box__title {
  height: 35%;
  transition: 1s;
}
.container .box a:hover .box__title p, .container .add_box a:hover .box__title p {
  opacity: 1;
}
.container .box img, .container .add_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.container .box .box__title, .container .add_box .box__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #f6f6f6;
  border-radius: 0 0 8px 8px;
  vertical-align: middle;
  transition: 0.5s;
  transition-timing-function: ease-out;
}
.container .box .box__title p, .container .add_box .box__title p {
  opacity: 0;
  text-align: center;
  font-size: 0.8em;
  margin: 0;
  transition-delay: 0.2s;
  transition: 0.2s;
  transition-timing-function: ease-out;
}
.container .box .box__title h6, .container .add_box .box__title h6 {
  text-align: center;
  text-transform: uppercase;
  margin: 7% 0 0 0;
  font-size: 0.9em;
}
.container .add_box {
  background-color: #f9f9f9;
}
.container .add_box a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.container .add_box svg {
  display: block;
  margin: auto;
  margin-top: 23.5%;
  transition: 0.3s;
  transition-timing-function: ease-out;
}
.container .add_box .add_box__title {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #bababa;
  top: 60%;
}
.container .add_box .add_box__title h6 {
  text-transform: uppercase;
}
.container .add_box a:hover svg {
  margin-top: 21.5%;
}

