#toggle{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin: 0 200px;
    text-align: center;
}

.hemlo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin: 30px;
}

.services-right-text,
.services-left-art{
text-align: right;
}

.hemlo ul li{
    line-height: 50px;
    cursor: pointer;
  position: relative;
  background: white;
  margin-bottom: 2em;
  padding: 1em;
  font-weight: bold;
  font-size: 1em;
  border: none;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07); 
}
@media only screen and (max-width: 600px) {
.services-left-art{
    display: none;
}
.hemlo ul li{
    line-height: 20px;
    width: 100%;
}#toggle{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 0 0px;
    text-align: center;
}

.hemlo{
    display: block;
    grid-gap: 10px;
    margin: 30px;
}
  }